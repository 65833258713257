import { qs } from '../dom-helpers';

class ToTopButton {
  constructor() {
    this.toTopButton = qs('.to-top-button');
    this.header = qs('.header');
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    if (this.header) {
      window.addEventListener('scroll', () => this._handleStickyHeader());
    }
  }

  _handleStickyHeader() {
    if (window.scrollY > 0) {
      this.toTopButton.classList.add('to-top-button--visible');
    } else {
      this.toTopButton.classList.remove('to-top-button--visible');
    }
  }
}

export default ToTopButton;
