import { qs } from '../dom-helpers';

class Header {
  constructor() {
    this.hamburger = qs('.hamburger');
    this.navMenu = qs('.header__menus');
    this.header = qs('.header');
    this.links = this.navMenu.querySelectorAll('.header__nav-menu-link');
    this.bodyTag = qs('body');
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
      this._setActiveMenuItem();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    if (this.hamburger) {
      this.hamburger.addEventListener('click', () => this._handleHamburgerMenu());
    }

    if (this.header) {
      window.addEventListener('scroll', () => this._handleStickyHeader());
    }

    document.addEventListener('click', (event) => this._handleDocumentClick(event));
  }

  _handleHamburgerMenu() {
    this.hamburger.classList.toggle('is-active');
    this.navMenu.classList.toggle('nav-menus--opened');
    this._setHiddenScroll();
  }

  _handleStickyHeader() {
    const offsetHeader = this.header.offsetTop;
    if (window.scrollY >= offsetHeader) {
      this.header.classList.add('header--sticky');
    }
    if (window.scrollY <= offsetHeader) {
      this.header.classList.remove('header--sticky');
    }
  }

  _handleDocumentClick(event) {
    if (!event.target.closest('.hamburger') && !event.target.closest('.header__menus')) {
      this.navMenu.classList.remove('nav-menus--opened');
      this.hamburger.classList.remove('is-active');
      this._setHiddenScroll();
    }
  }

  _setActiveMenuItem() {
    const currentURL = window.location.href;
    this.links.forEach((link) => {
      if (link.href === currentURL) {
        const menuItem = link.parentElement;
        menuItem.classList.add('header__nav-menu-item--is-active');
      } else {
        const menuItem = link.parentElement;
        menuItem.classList.remove('header__nav-menu-item--is-active');
      }
    });
  }

  _setHiddenScroll() {
    if (window.innerWidth <= 768) {
      if (this.navMenu.classList.contains('nav-menu--opened')) {
        this.bodyTag.style.overflow = 'hidden';
      } else {
        this.bodyTag.style.overflow = '';
      }
    }
  }
}

export default Header;
