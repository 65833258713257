import { qsAll } from '../dom-helpers';

class AccordionTabs {
  constructor() {
    this.tabButtons = qsAll('.tab-button');
    this.accordions = qsAll('.accordion');
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this.tabButtons.forEach((tab, index) => {
      tab.addEventListener('click', () => {
        this.tabButtons.forEach((btn) => btn.classList.remove('tab-button--is-active'));
        this.accordions.forEach((acc) => acc.classList.remove('accordion--is-active'));

        tab.classList.add('tab-button--is-active');
        this.accordions[index].classList.add('accordion--is-active');
      });
    });
  }
}

export default AccordionTabs;
